import { useParams, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import Markdown from 'markdown-to-jsx'
import logo from './assets/logo.png'

const Post = ({ entries = [] }) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [entry, setEntry] = useState(entries.find(e => e.slug === id))

  if (!entry) {
    return <div>Loading...</div>
  }

  const shareOnFacebook = () => {
    const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`
    window.open(url, '_blank', 'width=600,height=400')
  }

  const shareOnTwitter = () => {
    const url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}&text=${encodeURIComponent(entry.title)}`
    window.open(url, '_blank', 'width=600,height=400')
  }

  const shareOnWhatsApp = () => {
    const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(window.location.href)}`
    window.open(url, '_blank')
  }

  return (
    <div className="flash-news-container" style={{ direction: 'rtl' }}>
      <header style={{
        textAlign: 'center',
        padding: '2rem 0',
        display: 'flex',
        justifyContent: 'center',
        position: 'relative'
      }}>
        <a href="https://newsalist.net">
          <img 
            src={logo}
            alt="Newsalist Logo"
            width={300}
            style={{
              maxWidth: '300px',
              height: 'auto',
              display: 'block',
              margin: '0 auto'
            }}
          />
        </a>
      </header>
      <div style={{
        maxWidth: '800px',
        margin: '0 auto'
      }}>
        <article style={{ 
          padding: '2rem',
          margin: '2rem 0',
          backgroundColor: '#ffffff',
          borderRadius: '8px',
          boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
        }}>
          <p style={{
            color: '#666',
            fontSize: '0.9rem',
            marginBottom: '1rem'
          }}>
            {new Date(entry.date).toLocaleDateString('en-US', { 
              month: 'long', 
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              hour12: false
            })}
          </p>
          <h1 style={{ 
            fontSize: '2rem',
            marginBottom: '1.5rem',
            color: '#333'
          }}>
            {entry.title}
          </h1>
          <div style={{
            lineHeight: '1.8',
            fontSize: '1.2rem'
          }}>
            <Markdown>{entry.body}</Markdown>
          </div>
          <div style={{
            display: 'flex',
            gap: '1rem',
            marginTop: '2rem',
            justifyContent: 'center'
          }}>
            <button
              onClick={shareOnFacebook}
              style={{
                padding: '0.5rem 1rem',
                backgroundColor: '#1877f2',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer'
              }}
            >
              مشاركة على فيسبوك
            </button>
            <button
              onClick={shareOnTwitter}
              style={{
                padding: '0.5rem 1rem',
                backgroundColor: '#1da1f2',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer'
              }}
            >
              مشاركة على تويتر
            </button>
            <button
              onClick={shareOnWhatsApp}
              style={{
                padding: '0.5rem 1rem',
                backgroundColor: '#25d366',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer'
              }}
            >
              مشاركة على واتساب
            </button>
          </div>
        </article>
        <button 
          onClick={() => navigate('/')}
          style={{
            right: '1rem',
            top: '50%',
            transform: 'translateY(-50%)',
            padding: '0.5rem 1rem',
            backgroundColor: '#ff0000',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer'
          }}
        >
          عودة
        </button>
      </div>
    </div>
  )
}

export default Post