import useSWR from 'swr'
import { useState, useEffect } from 'react'
import { Spinner } from '@contentful/f36-spinner'
import { createClient } from 'contentful'
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom'
import Content from './Content'
import Post from './Post'

const client = createClient({
  space: process.env.CONTENTFUL_SPACE_ID,
  accessToken: process.env.CONTENTFUL_DELIVERY_TOKEN,
})

const ITEMS_PER_PAGE = 20

const fetcher = async (key, skip = 0) => {
  const entryItems = await client.getEntries({ 
    content_type: 'article',
    order: '-fields.date',
    limit: ITEMS_PER_PAGE,
    skip: skip,
    'fields.category.sys.id': '3yk6ZcPzfN9tGdhyGjwrEN'
  })

  const entries = entryItems.items.map((entry) => {
    const { fields } = entry
    return {
      title: fields.title,
      body: fields.body,
      date: fields.date,
      slug: fields.slug
    }
  })

  return { 
    entries,
    total: entryItems.total
  }
}

const ScrollHandler = () => {
  const location = useLocation()
  
  useEffect(() => {
    document.documentElement.style.scrollBehavior = 'auto'
    document.body.style.scrollBehavior = 'auto'
    window.history.scrollRestoration = 'manual'
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    })
  }, [location])

  return null
}

const App = () => {
  const [page, setPage] = useState(0)
  const { data, error, mutate } = useSWR(['contentful', page * ITEMS_PER_PAGE], ([key, skip]) => fetcher(key, skip))
  const [allEntries, setAllEntries] = useState([])
  const [loading, setLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)

  if (error) {
    console.log(error)
    return <div>failed to load</div>
  }
  
  if (!data && page === 0) return <Spinner size="large" />

  if (data && data.entries.length > 0 && allEntries.length === 0) {
    setAllEntries(data.entries)
    setHasMore(data.entries.length < data.total)
  }

  const loadMore = async () => {
    if (loading || !hasMore) return

    setLoading(true)
    const nextPage = page + 1
    const newData = await fetcher('contentful', nextPage * ITEMS_PER_PAGE)
    
    setPage(nextPage)
    setAllEntries(prev => [...prev, ...newData.entries])
    setHasMore(allEntries.length + newData.entries.length < newData.total)
    setLoading(false)
  }

  return (
    <Router>
      <ScrollHandler />
      <main>
        <Routes>
          <Route 
            path="/" 
            element={
              <Content 
                entries={allEntries} 
                onLoadMore={loadMore}
                loading={loading}
                hasMore={hasMore}
              />
            } 
          />
          <Route 
            path="/post/:id" 
            element={<Post entries={allEntries} />} 
          />
        </Routes>
      </main>
    </Router>
  )
}

export default App
