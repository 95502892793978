import { useEffect, useRef, useCallback } from 'react'
import logo from './assets/logo.png'
import { Spinner } from '@contentful/f36-spinner'
import Markdown from 'markdown-to-jsx'
import { useNavigate } from 'react-router-dom'

const Content = ({ entries, onLoadMore, loading, hasMore }) => {
  const observer = useRef()
  const lastEntryRef = useCallback(node => {
    if (loading) return
    
    if (observer.current) {
      observer.current.disconnect()
    }
    
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        onLoadMore()
      }
    })
    
    if (node) {
      observer.current.observe(node)
    }
  }, [loading, hasMore])

  const navigate = useNavigate()

  return (
    <div className="flash-news-container" style={{ direction: 'rtl' }}>
      <header style={{
        textAlign: 'center',
        padding: '2rem 0',
        display: 'flex',
        justifyContent: 'center',
        position: 'relative'
      }}>
        <a href="https://newsalist.net">
          <img 
            src={logo}
            alt="Newsalist Logo"
            width={300}
            style={{
              maxWidth: '300px',
              height: 'auto',
              display: 'block',
              margin: '0 auto'
            }}
          />
        </a>
      </header>
      <div style={{
        textAlign: 'center',
        padding: '1.5rem',
        margin: '1.5rem auto',
        maxWidth: '800px',
        backgroundColor: '#333',
        boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
      }}>
        <h2 style={{
          fontSize: '2rem',
          fontWeight: 'bold',
          marginBottom: '0.5rem',
          color: '#fff'
        }}>
          نشرة الأخبار 
        </h2>
        <p style={{
          fontSize: '1.2rem',
          color: '#fff',
          lineHeight: '1.5'
        }}>
          آخر التطورات في لبنان والعالم
        </p>
      </div>
      <div style={{
        display: 'flex',
        maxWidth: '800px',
        margin: '0 auto',
        marginBottom: '1.5rem',
        gap: '0.5rem'
      }}>
        <div style={{
          width: '15px',
          height: '15px',
          backgroundColor: '#ff0000',
          borderRadius: '50%',
          animation: 'blink 1s infinite'
        }}></div>
        <span style={{
          fontFamily: 'Arial, sans-serif',
          fontSize: '1.2rem'
        }}>
          أخبار 
        </span>
        <style>
          {`
            @keyframes blink {
              0% { opacity: 0; }
              50% { opacity: 1; }
              100% { opacity: 0; }
            }
          `}
        </style>
      </div>
      <div style={{
        maxWidth: '800px',
        margin: '0 auto'
      }}>
        {entries.map((entry, index) => {
          const truncatedBody = entry.body.split(' ').slice(0, 50).join(' ') + '...'
          
          return (
            <article 
              ref={index === entries.length - 1 ? lastEntryRef : null}
              key={entry.title + index} 
              className="flash-news-item"
              style={{ 
                padding: '1rem',
                margin: '1rem 0',
                borderRight: '4px solid #ff0000',
                boxShadow: '0 4px 6px rgba(0,0,0,0.15)',
                backgroundColor: '#ffffff',
                borderRadius: '4px',
                position: 'relative',
              }}
            >
              <p style={{
                color: '#666',
                fontSize: '0.9rem',
                marginBottom: '0.5rem'
              }}>
                {new Date(entry.date).toLocaleDateString('en-US', { 
                  month: 'long', 
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: false
                })}
              </p>
              <h3 style={{ 
                fontFamily: 'Arial, sans-serif',
                fontSize: '1.5rem',
                marginBottom: '0.5rem'
              }}>
                {entry.title}
              </h3>
              <div style={{
                lineHeight: '1.6',
                fontSize: '1.1rem',
                marginBottom: '1rem'
              }}>
                <Markdown>{truncatedBody}</Markdown>
              </div>
              <button
                onClick={() => navigate(`/post/${entry.slug}`)}
                style={{
                  backgroundColor: '#ff0000',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  padding: '0.5rem 1rem',
                  cursor: 'pointer'
                }}
              >
                اقرأ المزيد
              </button>
            </article>
          )
        })}
      </div>
      {loading && (
        <div style={{ textAlign: 'center', padding: '2rem' }}>
          <Spinner size="large" />
        </div>
      )}
    </div>
  )
}

export default Content
